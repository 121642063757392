<template>
  <div class="wrapper">
    <!-- 用户列表-->
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="用户ID">
          <el-input v-model="searchData.uid" placeholder="请输入" size="small"></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="searchData.nickname" placeholder="请输入" size="small"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" placeholder="请输入" size="small"></el-input>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>

    <tp-table :isNeedSerialNumber="false" :tableData="list" :columns="columns" :totalNum="total"
              :current-page.sync="currentPage" :pageSize.sync="pageSize"/>

    <el-dialog
        title="充值"
        :visible.sync="showUserBalanceForm"
        width="30%"
        :before-close="closeIncrBalanceForm">
      <el-form ref="form" :model="userBalanceFormData" label-width="80px">
        <el-form-item label="充值金额">
          <el-input-number v-model="userBalanceFormData.balance" :precision="2" :step="0.1" :min="-10000000" :max="100000"
                           label="充值金额"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onChargeBalance">充值</el-button>
          <el-button @click="closeIncrBalanceForm">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {getListAPI, incrBalanceAPI} from './api'
import {authBtnMixin} from '@/mixins/authBtnMixin'
import {delAPI} from "@/views/recharge/charge-value/api";

const columns = [
  {
    label: '用户ID',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '昵称',
    prop: 'mini_user',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['mini_user']?.['nickname']}</p>
    }
  },

  {
    label: '手机号码',
    prop: 'mobile',
    minWidth: '140',
    customRender(h, row) {
      return <p>{row['mobile']}</p>
    }
  },
  {
    label: '等级',
    prop: 'level',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['level']}</p>
    }
  },
  {
    label: '余额',
    prop: 'balance',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['balance']}</p>
    }
  },
  {
    label: '冻结金额',
    prop: 'lock_balance',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['lock_balance']}</p>
    }
  },
  {
    label: '注册时间',
    prop: 'created_at',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['created_at']}</p>
    }
  },

  {
    label: '操作',
    minWidth: '150',
    fixed: 'right',
    customRender(h, row) {
      return (
          <div>
            <el-link
                disabled={!this.isShowBtn(this.AUTH_BTN.user_list_car)}
                style="margin:0 10px;"
                underline={false}
                type="primary"
                onClick={() => this.$router.push({name: 'UserCarList', params: {id: row['id']}})}
            >
              车辆
            </el-link>
            <el-link
                style="margin:0 10px;"
                disabled={!this.isShowBtn(this.AUTH_BTN.user_list_balance)}
                underline={false}
                type="primary"
                onClick={() => this.$router.push({name: 'UserBalanceList', params: {id: row['id']}})}
            >
              流水
            </el-link>

            <el-link
                underline={false}
                type="primary"
                onClick={() => this.openIncrBalanceForm(row['id'])}
            >
              充值
            </el-link>
          </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        nickname: '',
        mobile: '',
        realname: '',
        timeSlot: [],
        uid: ''
      },

      showUserBalanceForm: false,
      userBalanceFormData: {
        uid: 0,
        balance: 0,
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  methods: {
    openIncrBalanceForm(uid) {
      this.showUserBalanceForm = true
      this.userBalanceFormData.uid = uid
      this.userBalanceFormData.balance = 1
    },

    closeIncrBalanceForm() {
      this.showUserBalanceForm = false
    },

    onChargeBalance() {
      this.$confirm('是否充值, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            incrBalanceAPI(this.userBalanceFormData).then(res => {
              this.showUserBalanceForm = false
              this.getList()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
    },

    async getList() {
      const {nickname, mobile, timeSlot, uid} = this.searchData
      let params = {page: this.currentPage, page_size: this.pageSize, nickname, mobile, uid}
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      if (this.$route.query.id) {
        params.id = this.$route.query.id
      }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    handleRemove({id}) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            delAPI(id).then(() => {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getList()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
    },
    reset() {
      // 将this._data.searchData和this.$options.data().searchData合并到this._data.searchData上
      Object.assign(this._data.searchData, this.$options.data().searchData)
      if (this.$route.query.id) {
        this.$router.push({name: 'UserVipList'})
        // return
      }
      // 调用getList方法
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;

        .el-input {
          width: 250px;
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }

      // .el-select .el-input {
      //   width: 100px;
      // }
      // .input-with-select {
      //   vertical-align: middle;
      // }
      // .input-with-select .el-input-group__prepend {
      //   background-color: #fff;
      // }
    }
  }

  .el-divider--horizontal {
    margin: 0 0 10px;
  }

  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }

  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
